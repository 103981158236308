<template>
    <Overview 
        :moduleName="this.formatModuleName(this.$route.name, this.$i18n, false)"
        :moduleNamePlural="$t('overview.my_x', [this.formatModuleName(this.$route.name, this.$i18n)])"
        sort="id"
        :sortOrder="-1"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :extraOptions=extraOptions
        :modalSize="'modal-xl'"
        :modalWidth="'1400px'"
        :modalHeight="'90%'"
        :filterOptions="filterOptions"
        :checkIfCanShowDelete="checkIfCanShowDelete"
        :deleteText="setDeleteText"
        :autoSaveEnabled="true"
        :autoFocusInput="false"
        :showCopy="true"
        :showAddButton="canEditInvoices"
        :showDropdown="canEditInvoices"
        :blOverview="true"
        downloadFileType="pdf"
        ref="invoiceRef"
    >
        <template #columns>
            <Column field="office.relation.name" sortField="office_id" :header="$t('crm.company')"></Column>
            <Column field="customer.relation.name" :header="$t('navigation.customers')"></Column>
            <Column field="mainport_project.number" style='width:6.25rem' header="Project"></Column>
            <Column field="number" :header="$t('overview.number')" style='width:11.25rem' :sortOrder="-1">
                <template #body="slotProps">
                    {{slotProps.data.number}}&ensp;
                    <span v-if="slotProps.data.invoice_type" class="badge px-1 bg-warning">{{slotProps.data.invoice_type.name == 'Agent' ? 'Ag' : 'Cu'}}</span>
                </template>
            
            </Column>
            <Column field="date" :header="$t('overview.date')" style='width:5rem' :sortOrder="-1">
                <template #body="slotProps">
                    {{formatDate(slotProps.data.date, 'tinyDate')}}
                </template>
            </Column>
            <Column field="voyage" :header="$t('navigation.voyages')" style='width:7rem'>
                <template #body="slotProps">
                    {{slotProps.data.voyage ? slotProps.data.voyage.split(' ').at(-1) : ''}}
                </template>
            </Column>
            <Column field="port_of_loading" header="POL" style='width:7rem'>
                <template #body="slotProps">
                    {{slotProps.data.port_of_loading ? slotProps.data.port_of_loading.split(',')[0] : ''}}
                </template>
            </Column>
            <Column field="port_of_discharge" header="POD" style='width:7rem'>
                <template #body="slotProps">
                    {{slotProps.data.port_of_discharge ? slotProps.data.port_of_discharge.split(',')[0] : ''}}
                </template>
            </Column>
            <Column field="total_inc_vat" header="ROE" style='width:5rem' :sortable="false">
                <template #body="slotProps">
                    <div class="d-flex gap-1">
                        <div class="badge error-badge bg-success" v-for="exrate in slotProps.data.exchange_rates.filter(rate => rate.currency_id != slotProps.data.currency_id).slice(0, 2)" :key="exrate.id" @mouseenter="setPopoverPosition">
                            <span>{{exrate.currency.symbol}}</span>
                            <div style="position: relative" data-type="exrate">
                                <div class="text-bubble-ov">{{ Number(exrate.exchange_rate).toFixed(4) }}</div>
                            </div>
                        </div>
                    </div>
                </template>
            </Column>
            <Column field="total_inc_vat" :header="$t('operations.grand_total')" style='width:10rem' class="grandtotal">
                <template #body="slotProps">
                    {{slotProps.data.currency ? (formatCurrency(slotProps.data.total_inc_vat) + ' ' + slotProps.data.currency.code) : ''}}
                </template>
           </Column>
           <Column field="is_finalized" :header="$t('overview.status')" style='width:7rem' :sortable="false">
                <template #body="slotProps">
                    <div class="d-flex gap-2">
                        <div class="badge" :class="slotProps.data.is_mailed ? 'bg-success' : 'bg-secondary'">Mail</div>
                        <div class="badge bg-success" v-if="slotProps.data.export_status == 'Geëxporteerd'">Sync</div>
                        <div class="badge bg-danger error-badge position-relative" v-else-if="slotProps.data.export_status && (slotProps.data.export_status == 'Fout' || slotProps.data.export_status.toLowerCase().includes('error'))" @mouseenter="setPopoverPosition">
                            <span>Sync</span>
                            <div style="position: relative" data-type="error">
                                <div class="text-bubble-ov">{{ formatErrorText(slotProps.data.export_last_error) }}</div>
                            </div>
                        </div>
                        <div class="badge bg-secondary" v-else>Sync</div>
                    </div>
                </template>
           </Column>
        </template>

        <template #form>
            <InvoiceForm ref="ItemForm" :itemData="item" />
        </template>

        <template #modal-footer>
            <div class="d-flex gap-2" id="invoices-footer" :class="{'read-only': !canEditInvoices}">
                <FormItem type="download" id="finishbutton" :label="$t('form.finalize')" :service="itemFormRef ? itemFormRef.finishWithoutEmail : null" :disabled="!itemFormRef || !itemFormRef.id" startTag="fa fa-check-to-slot" />
                <button id="emailbutton" type="button" class="btn btn-primary" :disabled="!itemFormRef" @click.prevent="itemFormRef.email()">
                    <i class="fa fa-envelope"></i>&ensp;{{$t('accounting.email_invoice')}}
                </button>
                <FormItem type="download" id="pdfbutton" :label="$t('accounting.show_pdf')" :service="itemFormRef ? itemFormRef.showPDF : null" />
            </div>
        </template>
    </Overview>
</template>

<script>
import store from '@/store/user'
import Overview from '@/components/Overview.vue';
import Column from '@/components/column';
import InvoiceForm from '@/components/invoice/InvoiceFormNew.vue';
import InvoiceService from '@/services/InvoiceService';
import officeService from '@/services/FinanceSettingService';
import customerService from '@/services/CustomerService';
import moment from 'moment';

export default {
    components: {
        Overview,
        Column,
        InvoiceForm
    },
    data() {
        return {
            item: {},
            itemService: InvoiceService,
            itemFormRef: null,
            extraOptions: [],
            filterOptions: [
                {
                    field: 'office_id',
                    service: officeService,
                    name: this.$t('crm.company'),
                    multiple: true
                },
                {
                    field: 'customer_id',
                    service: customerService,
                    name: this.$t('navigation.customers'),
                    multiple: true
                }
            ],
            checkIfCanShowDelete: function(data){
                return !data.is_finalized;
            },
            setDeleteText: function(item){
                return 'invoice ' + item.number + ' for ' + (item.customer ? item.customer.relation.name : ' NMT');
            },
            setPopoverPosition(e){
                const el = e.target.children[1];
                el.style.bottom = (document.documentElement.scrollTop + 40) + 'px';
                if(el.dataset.type == 'exrate') el.style.left = '-1.25rem';
                else el.children[0].style.translate = '-50% 0';
            },
            formatErrorText(text){
                if(text.includes('<?xml')){
                    //eslint-disable-next-line
                    const regex = new RegExp("<message.+?>(.+)<\/message>");
                    const result = regex.exec(text);
                    console.log(result, text);
                    return result ? result[1] : text;
                }
                return text;
            }
        }
    },
    computed: {
        canEditInvoices(){
            return store.getters.canEditInvoices;
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
        const copyFunction = function(slotProps, isCredit, overview, isAdditional = false){
            let form = overview.$refs.invoiceRef;
            form.itemLoading = true;
            if(slotProps.data.job_booking_id){
                window.createNewJobInvoice = true;
                form.editItem(slotProps.data);
            }
            else{
                document.getElementById("deeplinkModalButton").click();
            }
            overview.$nextTick(() => {
                overview.itemFormRef.active(true);
                overview.$nextTick(() => {
                    overview.itemFormRef.reset();
                    form.isCopying = true;
                    InvoiceService.show(slotProps.data.id).then(response => {
                        form.itemLoading = false;
                        form.item = overview.itemFormRef.getData();
                        form.item.isCopy = true;
                        let data = response.data;
                        data.is_finalized = 0;
                        data.is_mailed = 0;
                        data.is_exported = 0;
                        data.export_status = null;
                        data.date = moment().format('YYYY-MM-DD');
                        delete data.finalized;
                        delete data.id;
                        if(isAdditional){
                            data.invoice_lines = [];
                        }
                        data.invoice_lines.forEach(line => {
                            line.sales_rate = Number(line.sales_rate) * -1;
                            line.order_by += 1;
                            line.id = null;
                        });
                        data.invoice_lines.splice(0, 0, {
                            amount: 0,
                            bill_of_lading_id: null,
                            buying_rate: null,
                            buying_rate_calculation_unit_id: null,
                            buying_rate_currency_id: 2,
                            calc_fac: true,
                            collect: null,
                            deleted_at: null,
                            description: (isAdditional ? "Additional" : (isCredit ? "Credit" : "Debit")) +  " to our invoice " + (response.data.number + ""),
                            description_vins: null,
                            description_vins_header: null,
                            id: null,
                            invoice_id: null,
                            is_approved: true,
                            line_type: null,
                            order_by: 0,
                            quantity: "1",
                            sales_rate: "0",
                            sales_rate_calculation_unit_id: null,
                            sales_rate_currency_id: 2,
                            supplier_id: null,
                            vat: null
                        });
                        data.number = '';
                        if(slotProps.data.job_booking_id){
                            data.id = null;
                            data.cuid = 999;
                            window.newJobInvoiceData = data;
                            form.$refs.jobform.setCopyInvoiceData(data);
                        }
                        else{
                            overview.itemFormRef.setData(data);
                        }
                    }).catch(error => {
                        overview.toastError(error)
                        console.log('error', error) // create notification-toaster for user error
                    })
                });
            });
        }
        this.extraOptions = [{
            icon: 'copy',
            name: this.$t('accounting.credit_invoice'),
            show: (slotProps) => {return slotProps.data.total_inc_vat > 0},
            function: (slotProps) => copyFunction(slotProps, true, this)
        }, {
            icon: 'copy',
            name: this.$t('accounting.debit_invoice'),
            show: (slotProps) => {return slotProps.data.total_inc_vat < 0},
            function: (slotProps) => copyFunction(slotProps, false, this)
        }, {
            icon: 'copy',
            name: this.$t('accounting.additional_invoice'),
            function: (slotProps) => copyFunction(slotProps, false, this, true)
        }];
        
        if(store.getters.getCompanyHq)
        {
            this.extraOptions.push({
                icon: 'link',
                name: this.$t('masterdata.export'),
                show: (slotProps) => {return slotProps.data.export_destination != 'exactglobe'},
                function: (slotProps) => {
                    InvoiceService.export(slotProps.data.id).then(response => {
                        this.$toast.success("Export successful");
                    }).catch(error => {
                        this.toastError(error)
                    });
                }
            });
            
            this.extraOptions.push({
                icon: 'link',
                name: this.$t('masterdata.export'),
                show: (slotProps) => {return slotProps.data.export_destination == 'exactglobe'},
                function: (slotProps) => {
                    InvoiceService.resetGlobe(slotProps.data.id).then(response => {
                        this.$toast.success("Export successful");
                    }).catch(error => {
                        this.toastError(error)
                    });
                }
            });
        }
        
    }
}
</script>
<style>
    #invoices-footer.read-only button:not(#pdfbutton){
        pointer-events: none;
        visibility: hidden;
    }
    #invoices-footer.read-only + #savebutton{
        display: none
    }
</style>